import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './global.css';
import './global_classes.css';
import store from './store/store';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
// import { AxiosConfig } from './axiosConfig';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

// AxiosConfig()

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme(
    {
        typography: {
            fontFamily: 'var(--primary-font-family)',
        },
    }
)

root.render(
    <React.Fragment>
        <HashRouter>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <PersistGate persistor={persistStore(store)}>
                        <App />
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </HashRouter>
    </React.Fragment>
);

reportWebVitals();
