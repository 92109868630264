import { Box, Button, useMediaQuery } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { digest_action } from '../store/digest/digest_action';
import { toggleLoader } from '../store/loader/loaderReducer';
import PDFDecline from './PDFDecline';
import PDFSign from './PDFSign';
import ViewDocModal from './ViewDocModal';
import ErrorPage from '../component/ErrorPage';
import './SignDocument.css';

const SignDocument = () => {
  const [documentSign, setDocumentSign] = useState(false);
  const [properties, setProperties] = useState(null);
  const [file, setFile] = useState(null);
  const [info, setInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [decline, setDecline] = useState(false);
  const [is_producted, setIs_producted] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorPage, setErrorPage] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const digestDetails = useSelector((state) => state?.digestDetails?.value);
  const draftType = window.location.href.split('?')[1]?.length;
  const is_mobile = useMediaQuery('(min-width: 600px)');
  const is_small_screen = useMediaQuery('(min-width: 370px)');

  let url = window.location.href;
  let digest = draftType ? url.split('=')[1] : digestDetails;

  useEffect(() => {
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    if (reload[0] === 'reload') {
      navigate(`?digest=${digest}`);
    }
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    dispatch(digest_action(digest));
    if (digest) {
      axios.get(process.env.REACT_APP_SIGNREQUEST, { params: { digest: decodeURIComponent(digest) }, withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setIs_producted(res?.data?.is_protected);
          if (!res?.data?.is_protected) handleSignRequestDoc('get');
          if (res?.data?.is_protected) dispatch(toggleLoader(false));
          setProperties(res.data);
          setInfo(res.data);
        }).catch((err) => {
          dispatch(toggleLoader(false));
          if (err?.response?.data === 'invalid_recipient_digest') {
            setErrorPage(true);
          } else {
            toast.error(err.message);
          }
        })
    } else {
      dispatch(toggleLoader(false));
      setErrorPage(true);
    }
  }, []);

  const handleSignReq = (value) => {
    dispatch(toggleLoader(true));
    let params = { digest: decodeURIComponent(digest), access_code: value }
    axios.post(process.env.REACT_APP_PROTECTEDTEMPLATE, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = res?.data;
        setProperties(data);
        setIs_producted(false);
        setModal(false);
        handleSignRequestDoc('post', value);
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        let error = value ? err?.response?.data?.response : err?.response?.data?.access_code;
        toast.error(error ?? err.message);
      });
  };

  const handleSignRequestDoc = (type, value) => {
    let param = { digest: decodeURIComponent(digest) };
    if (value) param['access_code'] = value;

    if (type === 'get') {
      axios.get(process.env.REACT_APP_SIGNREQUESTDOC, { responseType: 'blob', params: param, withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setFile(res.data);
          // dispatch(toggleLoader(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    } else {
      axios.post(process.env.REACT_APP_SIGNREQUESTDOC, param, { responseType: 'blob', withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setFile(res.data);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  };

  const handleInformation = () => {
    setDocumentSign(false);
  };

  const obj = [
    {
      name: "Document Name",
      value: info?.document_information?.Document_Name
    },
    {
      name: "Company Name",
      value: info?.sender_information?.organization_name
    },
    {
      name: "Sender Email",
      value: info?.sender_information?.email_id
    },
    {
      name: "Description",
      value: info?.public_note
    },
    {
      name: 'Expired on',
      value: info?.expiry_date === 0 ? 'No Expiry' : moment(new Date(info?.expiry_date)).format('DD/MM/YYYY LT')
    }
  ];

  return (
    <Box>
      {errorPage ?
        <ErrorPage />
        :
        (<Box>
          {!documentSign ? (
            <Box className='doc-mask'>
              <Box className='doc-wrapper'>
                <Box
                  className='doc-delete-container'
                  style={{
                    maxWidth: is_mobile ? '500px' : is_small_screen ? '360px' : '300px',
                    padding: is_mobile && '5px 30px 5px 20px',
                  }}
                >
                  <Box className='doc-body'>
                    <Box className='doc-text'>Document Information</Box>
                    {obj?.map((list, i) => (
                      <Box className='doc-info-text' key={i}>
                        <Box className='doc-name'>{list?.name}</Box>
                        <Box className='doc-name-space'>:</Box>
                        <Box
                          className={
                            is_small_screen ? 'doc-right-text' : 'doc-right-text-small-screen'
                          }
                        >
                          {list?.value}
                        </Box>
                      </Box>
                    ))}
                    <Box className='terms'>
                      By clicking Accept, you've read the{' '}
                      <a className='terms-link' href='test'>
                        "Electronic Signature Terms"
                      </a>{' '}
                      and consent to use centilio electronic signatures.
                    </Box>
                  </Box>
                  <Box className='doc-buttons'>
                    <Button variant='contained' className='doc-accept' onClick={() => setDocumentSign(true)}>
                      Accept
                    </Button>
                    <Button variant='outlined' className='doc-cancel' onClick={() => [setShowModal(true), setDecline(true)]}>
                      Decline
                    </Button>
                  </Box>
                </Box>
              </Box>
              {decline && (
                <PDFDecline modal={() => setShowModal(false)} showModal={showModal} />
              )}
            </Box>
          ) : is_producted ?
            <ViewDocModal
              info={info}
              handlePasscode={handleSignReq}
              modal={modal}
              file={file}
            /> : (
              <PDFSign
                properties={properties}
                file={file}
                handleInformation={handleInformation}
              />
            )}
        </Box>)}
    </Box>
  );
};

export default SignDocument;
